import {POST,GET,DELETE,PATCH,PUT} from './axios'

// /phoebus/equipmentPanel/queryEccLevel_form

export default  {
    // 查询用户账号下的子账号
    isOnlyUser:(data)=> POST.post_form('/phoebus/loginNew/acList',data),
    // 登录接口
    loginin:(data)=>POST.post_form('/phoebus/login/loginNew',data),
    // 退出登录 /login/loginOut
    loginOut:(data)=>POST.post_form('/phoebus/loginNew/loginOut',data),
    // 获取地图类型
    getServerLocation:(data)=>POST.post_form('/phoebus/websiteLocation/getLocation',data),
    // 校验账号是否需要激活
    isAacitve:(data)=>POST.post_form('/phoebus/loginNew/enable',data),
    // /login/agreeMents
    loginAgreeMents:(data)=>POST.post_form('/phoebus/login/agreeMents',data),
    // 注册时查询邮箱号是否被注册
    isOnlyUserName:(data)=>POST.post_form('/phoebus/login/existUser',data),
    // 用户注册
    loginNewReg:(data)=>POST.post_form('/phoebus/loginNew/reg',data),
    // 是否存在电站
    siteExist:(data)=>POST.post_form('/phoebus/site/exist',data),
    // 激活状态查询
    queryActiveStatus:(data)=>POST.post_form('/phoebus/loginNew/status',data),
    // 查询用户是否自行注册
    loginNewFlag:(data)=>POST.post_form('/phoebus/loginNew/flag',data),
    // 用户激活接口
    loginNewActive:(data)=>POST.post_form('/phoebus/loginNew/active',data),
    // 用户忘记密码发送邮件的接口
    sendCode:(data)=>POST.post_form('/phoebus/loginNew/fwdMail',data),
    // 修改密码的接口 /loginNew/fwd
    editPaw:(data)=>POST.post_form('/phoebus/loginNew/fwd',data),
    // 电站管理-电站分页查询
    getSitePage:(data)=>POST.post_form('/phoebus/site/getPage',data),
    // 查询国家列表
    selectCountry:(data)=>POST.post_form('/phoebus/area/selectCountry',data),
    // 获取国家区号的接口 area/areaCodes
    areaCodes:(data)=>POST.post_form('/phoebus/area/areaCodes',data),
    // 获取时区列表
    getTimeZoneList:(data)=>POST.post_form('/phoebus/timeZone/getList',data),
    // 新增电站校验电站名称是否重复 /phoebus/site/getUserSiteNum
    getUserSiteNum:(data)=>POST.post_form('/phoebus/site/getUserSiteNum',data),
    // 新增电站校验Sn是否合法 /phoebus/site/wifiIslegal
    wifiIslegal:(data)=>POST.post_form('/phoebus/site/wifiIslegal',data),
    // 通过国家获取卡套餐
    getPackageByCountry: (data) => POST.post_form('/phoebus/vodafoneCard/queryPackageByName', data),
    // 根据时区获取夏令时
    getSummerTime: (data) => POST.post_form('/phoebus/timeZone/getSummerTime', data),
    // 校验nmi
    siteCheckNmi: (data) => POST.post_form('/phoebus/site/checkNmi', data),
    // 解绑nmi
    spannmiValidateUnbindCode: (data) => POST.post_form('/phoebus/sapnnmi/validateUnbindCode', data),
    // 新增电站 /site/addEntity
    // addSite: (data) => POST.post_form('/phoebus/site/addEntity', data),
    addSite: (data) => POST.post_file1('/phoebus/site/addEntity221205', data),
    // 编辑电站信息 /site/editEntity
    editSite: (data) => POST.post_form('/phoebus/site/editEntity', data),
    // 文件新增设备 /phoebus/site/siteLinkWifiSnBatch
    newAddModel:(data) => POST.post_file1('/phoebus/site/siteLinkWifiSnBatch', data),
    // 新增设备 /phoebus/site/siteLinkWifiSn/New
    siteLinkWifiSnNew: (data) => POST.post_form('/phoebus/site/siteLinkWifiSnNew', data),
    // 查询数据权限的设备 /phoebus/sale/getSalFlow
    saleGetSalFlow:(data) => POST.post_form('/phoebus/sale/getSalFlow', data),
    //批量查询字典
    getDictionaryBatch: (data) => POST.post_form('/phoebus/dictionary/getDictionaryBatch', data),
    // 根据电站ID查询电站信息 /phoebus/site/getById
    siteGetById: (data) => POST.post_form('/phoebus/site/getById', data),
    // 查询电站设备列表
    getSiteWifiSNPage: (data) => POST.post_form('/phoebus/site/getNewSiteWifiSNPage', data),
    // 更改设备名称 /phoebus/wifi/insertNote
    wifiInsertNote: (data) => POST.post_form('/phoebus/wifi/insertNote', data),
    // 获取用户电站邮箱
    userCheckUserEmail:(data) => POST.post_form('/phoebus/user/checkUserEmail', data),
    // 发送验证码
    delSiteVerification:(data) => POST.post_form('/phoebus/site/delSiteVerification', data),
    // 终端用户删除电站检验验证码
    siteCheckVerificationCode:(data) => POST.post_form('/phoebus/site/checkVerificationCode', data),
    // 终端用户删除电站 /phoebus/site/deleteBatch
    siteDeleteBatch:(data) => POST.post_form('/phoebus/site/deleteBatch', data),
    // 查看备用密码
    snGetPassword:(data) => POST.post_form('/phoebus/sn/getPassword', data),
    // 设备解绑 /wifi/unbundlingWifiSN.do
    unbundlingWifiSN:(data) => POST.post_form('/phoebus/wifi/unbundByWifiSN', data),
    // 获取货币种类
    getCurrency: (data) => POST.post_form('/phoebus/site/getCurrency', data),
    // 查看是否可以设置自动获取电价 /deeparea/exist
    deeppareaExist: (data) => POST.post_form('/phoebus/deeparea/exist', data),
    // 获取电站设置的电价
    siteGetPrice: (data)=> POST.post_form('/phoebus/site/getPrice',data),
    // 获取区域数据
    deepareaGetAreaByNation: (data)=> POST.post_form('/phoebus/deeparea/getAreaByNation',data),
    // 获取区域电价
    elecGetPriceByNation: (data)=> POST.post_form('/phoebus/elec/getPriceByNation',data),
    // 保存设置的电价
    siteSetPrice: (data)=> POST.post_form('/phoebus/site/setPrice',data),
    // 数据权限控制
    saleEditSalFlow: (data)=> POST.post_form('/phoebus/sale/editSalFlow',data),
    // 获取制造商列表
    manufacturerSllManu: (data)=> POST.post_form('/phoebus/manufacturer/allManu',data),
    // 根据SN请求光伏数据 /tModule/selectModuleBySn
    tModuleSelectModuleBySn: (data)=> POST.post_form('/phoebus/tModule/selectModuleBySn',data),
    // 请求光伏模块列表数据
    tModuleTModuleData: (data)=> POST.post_form('/phoebus/tModule/tModuleData',data),
    //
    getPVBySNAndModuleId: (data)=> POST.post_form('/phoebus/devMoudle/getPVBySNAndModuleId',data),
    // 请求光伏芯片列表 /tMateria/allData
    tMateriaAllData: (data)=> POST.post_form('/phoebus/tMateria/allData',data),
    // 保存光伏设置 /tModule/addEntity
    tModuleAddEntity: (data)=> POST.post_form('/phoebus/tModule/addEntity',data),
    // 请求设备信息数据 /phoebus/device/getInverterFromRedis
    getInverterFromRedis: (data)=> POST.post_form('/phoebus/device/getInverterFromRedis',data),
    // 电池统计图数据接口 /battery/getBatteryData
    batteryGetBatteryData: (data)=> POST.post_form('/phoebus/battery/getBatteryData',data),
    // 获取电池历史信息 /phoebus/battery/getHistoryBatterys
    getHistoryBatterys: (data)=> GET.get('/phoebus/battery/getHistoryBatterys',data),
    // 获取逆变器统计分析数据 /phoebus/inverterStatistics/getInverterHistoryData
    getInverterHistoryData: (data)=> POST.post_form('/phoebus/inverterStatistics/getInverterHistoryData',data),
    // 获取设备机型 /phoebus/device/getTypeModelBySN
    getTypeModelBySN: (data)=> POST.post_form('/phoebus/device/getTypeModelBySN',data),
    // 获取设备发电量 /phoebus/inverterIndex/getYield
    inverterIndexGetYield: (data)=> POST.post_form('/phoebus/inverterIndex/getYield',data),
    //获取功率曲线数据
    getPowerByInverter: (data)=> POST.post_form('/phoebus/inverterIndex/getInverterState', data),
    // 数据报表非日报 /phoebus/export/getReportData
    getReportData: (data)=> POST.post_form('/phoebus/export/getReportData', data),
    // 数据报表日报 /phoebus/export/getReportDataPage
    getReportDataPage: (data)=> POST.post_form('/phoebus/export/getReportDataPage', data),
    // 获取充电桩上次更新时间和状态
    getUpdataTimeStatus: (data)=> POST.post_form('/phoebus/devchargepile/timeStatus', data),
    // 获取充电桩电量数据 /phoebus/devchargeindex/getYield
    devchargeGetYield: (data)=> POST.post_form('/phoebus/devchargeindex/getYield', data),
    // 查询充电桩充电记录 /phoebus/devchargerecord/getPage
    devchargerecordPage: (data)=> POST.post_form('/phoebus/devchargerecord/getPage', data),
    // 查询充电桩日报数据
    getChangeDayData: (data)=> POST.post_form('/phoebus/devchargeindex/getReportDataPage',data),
    // 查询充电桩非日报数据
    devchargeindexGetReportData: (data)=> POST.post_form('/phoebus/devchargeindex/getReportData',data),
    // 查询DataHub的更新时间和在线状态 /dataLogger/info
    dataLoggerInfo: (data)=> POST.post_form('/phoebus/dataLogger/info',data),
    // 查询DataHub的数据 /devdatahubdetail/list
    devdatahubdetailList: (data)=> POST.post_form('/phoebus/devdatahubdetail/list',data),
    // 获取光伏发电量大卡片数据 /phoebus/overview/pvCard
    overviewPvCard: (data)=> GET.get('/phoebus/overview/pvCard',data),
    // 获取光伏发电日月年发电量数据 /phoebus/overview/pvEnergyStt
    overviewPvEnergyStt: (data)=> GET.get('/phoebus/overview/pvEnergyStt',data),
    // 获取收益卡的数据 /phoebus/overview/profitCard
    overviewProfitCard: (data)=> GET.get('/phoebus/overview/profitCard',data),
    // 获取电池卡的数据 /phoebus/overview/batteryCard
    overviewBatteryCard: (data)=> GET.get('/phoebus/overview/batteryCard',data),
    // 获取电子地图卡中的CO2和收益数据 /phoebus/overview/mapCard
    overviewMapCard: (data)=> GET.get('/phoebus/overview/mapCard',data),
    // 查询电站下是否含有充电桩 devchargepile/existPile
    chargeShow: (data)=> GET.get('/phoebus/devchargepile/existPile',data),
    // 获取能量小卡的数据 /phoebus/overview/powerCard
    overviewPowerCard: (data)=> GET.get('/phoebus/overview/powerCard',data),
    // 获取能量统计卡<功率统计卡> /site/getSiteTotalPower
    getSiteTotalPower: (data)=>POST.post_form('/phoebus/site/getSiteTotalPower',data),
    // 获取设备状态卡信息 /phoebus/overview/dsCard
    overviewDsCard: (data)=> GET.get('/phoebus/overview/dsCard',data),
    // 获取电站状态卡信息 /phoebus/overview/ssCard
    overviewSsCard: (data)=> GET.get('/phoebus/overview/ssCard',data),
    // 获取未读消息数量在vuex中调用 /phoebus/disspushmsg/msgCount
    disspushmsgMsgCount:(data)=>POST.post_form('/phoebus/disspushmsg/msgCount',data),
    // 获取用户普通消息 /phoebus/disspushmsg/msgPage
    disspushmsgMsgPage:(data)=>POST.post_form('/phoebus/disspushmsg/msgPage',data),
    // 获取详细信息
    getHtml:(url,data)=> GET.get_htmlDom(url,data),
    // 普通消息已读 /phoebus/disspushmsg/readStatus
    disspushmsgReadStatus:(data)=>POST.post_form('/phoebus/disspushmsg/readStatus',data),
    // 普通消息删除 /phoebus/disspushmsg/userDelete
    disspushmsgUserDelete:(data)=>POST.post_form('/phoebus/disspushmsg/userDelete',data),
    // 获取运维消息 /phoebus/pushnews/getPushNewsContents
    getPushNewsContents:(data)=>POST.post_form('/phoebus/pushnews/getPushNewsContents',data),
    // 获取世界地图的svg
    getEchartsWorldMapSvg:()=>GET.getEcharts('/echarts/examples/data/asset/geo/Map_of_Iceland.svg'),
    // 获取地图看板的滚动内容和右侧需要展示的板块 /phoebus/usermapconfig/getConfig
    usermapconfigGetConfig:(data)=>GET.get('/phoebus/usermapconfig/getConfig',data),
    // 保存地图看板滚动数据和右侧需要展示的模板的接口 /phoebus/usermapconfig/save
    usermapconfigSave:(data)=>POST.post_form('/phoebus/usermapconfig/save',data),
    // 地图看板电站分布数据 /phoebus/usermapconfig/mapSiteTable
    usermapconfigMapSiteTable:(data)=>GET.get('/phoebus/usermapconfig/mapSiteTable',data),
    // 地图看板查看echarts提示组件中数据 /phoebus/usermapconfig/mapSiteView
    usermapconfigMapSiteView:(data)=>GET.get('/phoebus/usermapconfig/mapSiteView',data),
    // 地图看板发电量统计 /phoebus/overview/mapEnergy
    overviewMapEnergy:(data)=>GET.get('/phoebus/overview/mapEnergy',data),
    // 地图看板收益和co2减排 /phoebus/overview/mapProfitCard
    overviewMapProfitCard:(data)=>GET.get('/phoebus/overview/mapProfitCard',data),
    // 地图过去一周的电量统计 /phoebus/overview/mapChart
    overviewMapChart:(data)=>GET.get('/phoebus/overview/mapChart',data),
    // 查询质保列表 /phoebus/quality/getPage
    qualityGetPage:(data)=>POST.post_form('/phoebus/quality/getPage',data),
    // 查询质保列表crm /phoebus/crm/getPageGet
    crmGetPage:(data)=>GET.get('/phoebus/crm/getPage',data),
    // 回显质保信息 /phoebus/crm/getPageGet
    crmGetCrmInfo:(data)=>GET.get('/phoebus/crm/getCrmInfo',data),
    // 升级或编辑质保 /phoebus/crm/getPageGet
    crmUpgrade:(data)=>POST.post_json('/phoebus/crm/upgrade',data),
    // 根据id获取用户信息 /phoebus/user/getById
    idGetUserInfo:(data)=>POST.post_form('/phoebus/user/getById',data),
    // /orderpaypalconfig/getInverterTypeList
    orderpaypalconfigGetInverterTypeList:(data)=>POST.post_form('/phoebus/orderpaypalconfig/getInverterTypeList',data),
    // 校验是否存在黑名单SN /crmblack/exist
    crmblackExist:(data)=>POST.post_form('/phoebus/crmblack/exist',data),
    // 查询SN对应的设备类型和机型
    orderpaypalconfigGetInverterType:(data) =>POST.post_form('/phoebus/orderpaypalconfig/getInverterType',data),
    // 校验电池SN
    crmbatteryqualitySnCheck: (data) =>POST.post_form('/phoebus/crmbatteryquality/snCheck', data),
    // 新增质保 /phoebus/quality/save
    addWarranty:(data)=>POST.post_form('/phoebus/quality/save',data),
    // 根据质保数据ID获取质保信息 /phoebus/quality/getById
    WarrantyGetById:(data)=>POST.post_form('/phoebus/quality/getById',data),
    // 编辑质保 /phoebus/qualityNew/update
    qualityUpdate:(data)=>POST.post_form('/phoebus/qualityNew/update',data),
    // 删除质保 /phoebus/quality/delete
    qualityDelete:(data)=>POST.post_form('/phoebus/quality/delete',data),
    // 我的获取用户信息 /phoebus/login/getUserDetails
    getUserDetails:(data)=>POST.post_form('/phoebus/login/getUserDetails',data),
    // 保存用户修改的信息 /phoebus/login/editUserDetails
    editUserDetails:(data)=>POST.post_form('/phoebus/login/editUserDetails',data),
    // 用户修改密码 /phoebus/user/updatePwd
    userUpdatePwd:(data)=>POST.post_form('/phoebus/user/updatePwd',data),
    // 查询用户邮箱 /phoebus/user/checkUserEmail
    checkUserEmail:(data)=>POST.post_form('/phoebus/user/checkUserEmail',data),
    // 发送用户注销验证码 /phoebus/user/delUserVerification
    delUserVerification:(data)=>POST.post_form('/phoebus/user/delUserVerification',data),
    // 校验用户注销验证码 /phoebus/user/checkVerificationCode
    userCheckVerificationCode:(data)=>POST.post_form('/phoebus/user/checkVerificationCode',data),
    // 用户注销 /phoebus/user/deleteEntity202205
    closeAccount2022:(data)=>POST.post_form('/phoebus/user/deleteEntity202205',data),
    // 获取用户API /phoebus/api/getByUser
    getUserAPI:(data)=>POST.post_form('/phoebus/api/getByUser',data),
    // 获取API TokenID /phoebus/api/create
    createTokenID:(data)=>POST.post_form('/phoebus/api/create',data),
    // 图片卡上传图片 /phoebus/cardpicture/save
    cardpictureSave:(data)=> POST.post_file('/phoebus/cardpicture/save',data),
    // 查看用户上传的图片 /phoebus/cardpicture/list
    cardpictureList:(data)=> GET.get('/phoebus/cardpicture/list',data),
    // 删除上传的图片 /phoebus/cardpicture/deleteBatch
    cardpictureDeleteBatch:(data)=> POST.post_form('/phoebus/cardpicture/deleteBatch',data),
    // 查询时候联系方式 /phoebus/sercontact/list
    sercontactList:(data)=>POST.post_form('/phoebus/sercontact/list',data),
    //  查询用户的个性化设置 /phoebus/getPersonalization
    getPersonalization:(data)=> GET.get('/phoebus/personalize/getPersonalization',data),
    // 保存用户的个性化设置 /phoebus/updatePersonalization
    updatePersonalization:(data)=>POST.post_form('/phoebus/personalize/updatePersonalization',data),
    // 查询告警消息 /phoebus/alarmMsg/page
    alarmMsgPage:(data)=> GET.get('/phoebus/alarmMsg/page',data),
    // 告警消息状态修改 /phoebus/alarmMsg/readStatus
    alarmMsgReadStatus:(data)=>POST.post_form('/phoebus/alarmMsg/readStatus',data),
    // 删除告警消息 /phoebus/alarmMsg/delete
    alarmMsgDelete:(data)=>POST.post_form('/phoebus/alarmMsg/delete',data),
    // 获取操作建议 alarmPushGetAlarmInfo
    alarmPushGetAlarmInfo:(data)=> GET.get('/phoebus/alarmPush/getAlarmInfo',data),
    // 获取平台版本更新历史记录
    versionhistoryGetPage:(data)=>POST.post_form('/phoebus/versionhistory/getPage',data),
    // 查询平台维护记录集合
    versionhistoryList:(data)=>POST.post_form('/phoebus/versionhistory/list',data),
    // 延保码支付-金额列表 /orderpaypal/getAmount
    getAmount:(data)=>POST.post_form('/phoebus/orderpaypal/getAmount',data),
    // 延保码支付-金额列表 /crm/getAmount
    crmGetAmount:(data)=>GET.get('/phoebus/crm/getAmount',data),
    // 订单校验 /orderpaypal/checkCrmQuality
    checkCrmQuality:(data)=>POST.post_form('/phoebus/orderpaypal/checkCrmQuality',data),
    // 创建订单 /orderpaypal/createOrder
    orderpaypalCreateOrder:(data)=>POST.post_form('/phoebus/orderpaypal/createOrder',data),
    // 查询订单状态 /orderpaypal/getOrderStatus
    orderpaypalGetOrderStatus:(data)=>POST.post_form('/phoebus/orderpaypal/getOrderStatus',data),
    // 获取延保支付历史订单 /orderpaypal/getPage
    getOrderpaypal:(data)=>POST.post_form('/phoebus/orderpaypal/getPage',data),
    // 获取质保电池信息 /crmbatteryquality/getPage
    crmbatteryqualityGetPage:(data)=>POST.post_form('/phoebus/crmbatteryquality/getPage',data),
    // 查询电站代理商权限 /sale/getSiteSalFlow
    getSiteSalFlow:(data)=>POST.post_form('/phoebus/sale/getSiteSalFlow',data),
    // 修改代理商查看权限 /sale/editSiteVisiable
    editSiteVisiable:(data)=>POST.post_form('/phoebus/sale/editSiteVisiable',data),
    // 修改登录账号校验验证码是否正确 /login/regMailCode
    regMailCode:(data)=>POST.post_form('/phoebus/login/regMailCodeV5',data),
    // 修改登录账号时发送验证码 /login/sendMailCode
    sendMailCode:(data)=>POST.post_form('/phoebus/login/sendMailCode',data),
    // 修改登录账号 /login/updateSendMail
    updateSendMail:(data)=>POST.post_form('/phoebus/login/updateSendMail',data),
    // 用户修改邮箱发送验证码 /loginNew/emailCode
    emailCode:(data)=>POST.post_form('/phoebus/loginNew/emailCode',data),
    // 校验邮箱验证码是否正确 /loginNew/validateEmailCodeV5
    validateEmailCode:(data)=>POST.post_form('/phoebus/loginNew/validateEmailCodeV5',data),
    // 修改用户邮箱 /loginNew/updateEmail
    updateEmail:(data)=>POST.post_form('/phoebus/loginNew/updateEmail',data),
    // 查询电站下是否含有电池 /overview/existBattery
    existBattery:(data)=>POST.post_form('/phoebus/overview/existBattery',data),
    // 获取扫描状态（防止两人同时下发冲突指令）
    getIvTaskStatus:(data)=>POST.post_form('/phoebus/devIvData/status',data),
    // iv曲线开始扫描前调用的接口
    todayData:(data)=>POST.post_form('/phoebus/devIvData/check',data),
    // iv曲线请求扫描开始结束的接口
    IvScanOpen:(data)=>POST.post_form('/app_api/proxy/settingnew/paramSet',data),
    // 获取iv曲线数据
    getIvData:(data)=>POST.post_form('/phoebus/devIvData/getIvData',data), // 打包
    // iv曲线清除旧的数据
    learIvOldData:(data)=>POST.post_form('/phoebus/devIvData/clear',data),
    // iv曲线获取最近五次的扫描时间
    ivDate:(data)=>POST.post_form('/phoebus/devIvData/dayList',data),
    // 获取设备登录tokenId,取得访问权限
    loginRemoteLogin: (data) =>POST.post_form('/app_api/proxy/login/remoteLogin.do', data),
    // 全文匹配帮助文档 /phoebus/instrcontent/queryESContent
    queryHelpConten: (data) =>GET.get('/phoebus/instrcontent/queryESContent', data),
    // 查询富文本链接 /instrcontent/queryContent
    queryHelpContentUrl:(data) =>POST.post_form('/phoebus/instrcontent/queryContent', data),
    // 更新用户API
    updateTokenID:(data)=>PUT.put_query('/phoebus/api/updateTokenId',data),
    // 获取套餐列表
    agentGetApipricePage:(data)=> POST.post_form('/phoebus/apiprice/list',data),
    // 发起支付
    orderApiorder:(data)=>POST.post_form('/phoebus/apiorder/order',data),
    // 查询订单状态 /apiorder/status
    statusApiorder:(data)=> POST.post_form('/phoebus/apiorder/status',data),
    // 获取升级月份的接口 /apiorder/diff
    getUpdataMoon:(data)=>POST.post_form('/phoebus/apiorder/diff',data),
    //升级套餐 /apiorder/upOrder
    updataApiorder:(data)=> POST.post_form('/phoebus/apiorder/upOrder',data),
    // 获取API访问次数 /apiCount/count
    getApiCount:(data)=>POST.post_form('/phoebus/apiCount/count',data),
    // API访问低阈值告警 /apiCount/apiEmail
    apiCountApiEmail:(data)=>POST.post_form('/phoebus/apiCount/apiEmail',data),
    // 查询历史订单
    getPageApiorder:(data)=>POST.post_form('/phoebus/apiorder/getPage',data),
    pdfUserInfo:(data)=>GET.get('/phoebus/pdf/info',data),
    // 查询充电桩信息
    getFlowChartData:(data)=>POST.post_form('/phoebus/devchargepile/getFlowChartData',data),
    // 计划获取用户下的电站 unlSched/listSite
    getUnlSchedListSite:(data)=>GET.get('/phoebus/unlSched/listSite',data),
    // 计划获取用户下的设备 /unlSched/listDevice
    getUnlSchedListDevice:(data)=>GET.get('/phoebus/unlSched/listDevice',data),
    // 设备详情-电池分析-状态查看 /battery/getMutiBatteryData
    getMutiBatteryData:(data)=>POST.post_form('/phoebus/battery/getMutiBatteryData',data),
    // 查询用户调度计划 /unlSched/getSchedule
    getSchedule:(data)=>GET.get('/phoebus/unlSched/getSchedule',data),
    // 下发调度 /unlSched/issue
    dispatch:(data)=>POST.post_set('/phoebus/unlSched/issue',data),
    // 查询下发状态 /unlSched/getIssueResult/{taskId}
    getIssueResult:(data)=>GET.get_id('/phoebus/unlSched/getIssueResult/',data),
    // 重发 /unlSched/resend
    unlSchedResend:(data)=>GET.get('/phoebus/unlSched/resend',data),
    // 使能开关修改 update
    enableSwitch:(data)=>POST.post_set('/phoebus/unlSched/update',data),
    // 查询电站下拥有的设备 overview/siteDevTypes
    querySiteDev:(data)=>POST.post_form('/phoebus/overview/siteDevTypes',data),
    // 电站导出逆变器数据
    getSiteReportData:(data)=>GET.get('/phoebus/export/getSiteReportData', data),
    // 电站导出下充电桩数据 devchargerecord/getPage
    devchargerecordGetPage:(data)=>POST.post_form('/phoebus/devchargerecord/getPage',data),
    // 逆变器获取电池数据 /device/getInverterBatFromRedis
    getInverterBatFromRedis:(data)=>POST.post_form('/phoebus/device/getInverterBatFromRedis',data),
    // 获取电池版本信息
    getBatterySlaveInfo: (params) => GET.get('/phoebus/device/getBatterySlaveInfo', params),
    batteryBat: (params) => POST.post_form('/phoebus/battery/bat', params),
    // 获取用户跳转到CRM的信息 /quality/singleCrmInfo
    getCrmAuthInfo:(params) => POST.post_form('/phoebus/quality/singleCrmInfo', params),
    // 获取秒级数据可用参数
    tsInvParams:(params)=> GET.get('/phoebus/ts/inverter/params',params),
    getLiveData:(params)=> GET.get('/phoebus/ts/inverter/list',params),
    // 微逆组件数据 /inverterStatistics/getInvComponentData
    getInvComponentData:(params) => POST.post_form('/phoebus/inverterStatistics/getInvComponentData', params),
    // RSD列表 /devRsd/getPage
    devRsdGetPage:(params)=> POST.post_form('/phoebus/devRsd/getPage',params),
    // 查询RSD设备信息 /devRsd/getTypeModelBySN
    devRsdGetTypeModelBySN:(params) => GET.get('/phoebus/devRsd/getTypeModelBySN', params),
    // RSD 统计数据 /rsdStatistic/getRsdComponentData
    rsdStatisticGetRsdComponentData:(params) => POST.post_form('/phoebus/rsdStatistic/getRsdComponentData', params),
    // RSD 设备信息 /devRsd/getRsdFromRedis
    devRsdGetRsdFromRedis:(params) => POST.post_form('/phoebus/devRsd/getRsdFromRedis', params),
    // 查询用户主页展示的模块 /phoebus/sysModel/querySysModelByUserId
    querySysModelByUserId:(params) => GET.get('/phoebus/sysModel/querySysModelByUserId', params),
    // 修改展示的模块 /phoebus/sysModel/saveSysModel
    sysModelSaveSysModel:(params) => POST.post_json('/phoebus/sysModel/saveSysModel', params),
    // 查询设备总数和电站总数 /phoebus/site/getSiteCountAndInverterCount
    getSiteCountAndInverterCount:(params) => GET.get('/phoebus/site/getSiteCountAndInverterCount', params),
    // 查询收益 /phoebus/site/getIncomeByUserId
    getIncomeByUserId:(params) => GET.get('/phoebus/site/getIncomeByUserId', params),
    // 查询收益图表数据 /phoebus/site/getGenerationIncome
    getGenerationIncome:(params) => GET.get('/phoebus/site/getGenerationIncome', params),
    // 充放电图表数据 /phoebus/systemPanel/queryBatteryPower
    systemPanelQueryBatteryPower:(params) => GET.get('/phoebus/systemPanel/queryBatteryPower', params),
    // 查询电量图表数据 /phoebus/userIndex/getYield
    userIndexGetYield:(params) => GET.get('/phoebus/userIndex/getYield', params),
    // 查询功率 /phoebus/systemPanel/getUserAllSiteTotalPower
    systemPanelGetUserAllSiteTotalPower:(params) => POST.post_form('/phoebus/systemPanel/getUserAllSiteTotalPower', params),
    // 查询system layout布局数据 `/phoebus/equipmentPanel/queryInverterByInverterSpaceDto`
    queryInverterByInverterSpaceDto:(params) => POST.post_form('/phoebus/equipmentPanel/queryInverterByInverterSpaceDto', params),
    // 查询系统结构 拓扑图信息 /phoebus/equipmentPanel/queryInverterInSpace
    queryInverterInSpace:(params) => POST.post_form('/phoebus/equipmentPanel/queryInverterInSpace', params),
    // 查询逆变器能耗 `/phoebus/equipmentPanel/getInverterPower`
    equipmentPanelGetInverterPower:(params) => POST.post_form('/phoebus/equipmentPanel/getInverterPower', params),
    // 查询用户版本标识 `/phoebus/loginNew/queryVerFlag`
    loginNewQueryVerFlag:(params) => GET.get('/phoebus/loginNew/queryVerFlag', params),
    // 更新用户的版本标识 `/phoebus/loginNew/updateUser`
    loginNewUpdateUser:(params) => POST.post_form('/phoebus/loginNew/updateUser', params),
    // tou 新增模板 /phoebus/template/add
    touTemplateAdd:(params)=>POST.post_json('/phoebus/template/add',params),
    // tou 修改模板 /template/update
    templateUpdate:(params)=>POST.post_json('/phoebus/template/update',params),
    // tou 查询模板 /template/getList
    templateGetList:(params)=>POST.post_form('/phoebus/template/getList',params),
    // tou 删除模板 /phoebus/template/delete
    templateDelete:(params)=>POST.post_form('/phoebus/template/delete',params),
    // tou 查看可以tou的设备Sn /check/inverterList
    checkInverterList:(params)=>POST.post_form('/phoebus/check/inverterList',params),
    // tou 查询用户所有电站 /site/selectSite
    siteSelectSite:(params)=>POST.post_form('/phoebus/site/selectSite',params),
    // tou 获取模板列表 /phoebus/template/getIdList
    templateGetIdList:(params)=>POST.post_form('/phoebus/template/getIdList',params),
    // tou 校验策略的名称 /check/strategyName
    checkStrategyName:(params)=>POST.post_form('/phoebus/check/strategyName',params),
    // tou 检验规则名称 /check/ruleName
    checkRuleName:(params)=>POST.post_form('/phoebus/check/ruleName',params),
    // tou 选择模板后根据模板id查询模板数据 /phoebus/template/getOne
    templateGetOne:(params)=>POST.post_form('/phoebus/template/getOne',params),
    // tou 新增策略 /strategy/add
    strategyAdd:(params)=>POST.post_json('/phoebus/strategy/add',params),
    // tou 修改策略 /strategy/update
    strategyUpdate:(params)=>POST.post_json('/phoebus/strategy/update',params),
    // tou 删除策略 /strategy/delete
    strategyDelete:(params)=>POST.post_form('/phoebus/strategy/delete',params),
    // tou 查询策略列表 /strategy/list
    strategyList:(params)=>POST.post_form('/phoebus/strategy/list',params),
    // tou 查询策略详情 /strategy/detail
    strategyDetail:(params)=>POST.post_form('/phoebus/strategy/detail',params),
    // tou 日历查询策略个数 /strategy/count
    strategyCount:(params)=>POST.post_form('/phoebus/strategy/count',params),
    // tou 根据日期查询策略 /strategy/daily
    strategyDaily:(params)=>POST.post_form('/phoebus/strategy/daily',params),
    // tou 新增用户节假日 /holiday/add
    holidayAdd:(params)=>POST.post_json('/phoebus/holiday/add',params),
    // tou 更新用户节假日设置 /holiday/update
    holidayUpdate:(params)=>POST.post_json('/phoebus/holiday/update',params),
    // tou 查询用户设置的节假日 /holiday/list
    holidayList:(params)=>POST.post_form('/phoebus/holiday/list',params),
    // tou 删除节假日设置 /holiday/delete
    holidayDelete:(params)=>POST.post_form('/phoebus/holiday/delete',params),
    // tou 删除用下所有节假日设置 /holiday/deleteHoliday
    holidayDeleteHoliday:(params)=>POST.post_form('/phoebus/holiday/deleteHoliday',params),
    // tou 下发策略 /strategy/send
    strategySend:(params)=>POST.post_form('/phoebus/strategy/send',params),
    // tou 轮询下发状态 /strategy/refresh
    strategyRefresh:(params)=>POST.post_json('/phoebus/strategy/refresh',params),
    // tou 查询电站开关状态 /strategy/status
    strategyStatus:(params)=>POST.post_form('/phoebus/strategy/status',params),
    // tou 电站开关操作 /strategy/switch
    strategySwitch:(params)=>POST.post_form('/phoebus/strategy/switch',params),
    // tou 获取第三方的节假日数据 /holiday/common
    holidayCommon:(params)=>POST.post_form('/phoebus/holiday/common',params),
    // tou 校验tou模板名称 /check/templateName
    checkTemplateName:(params)=>POST.post_form('/phoebus/check/templateName',params),
    // 查询ecc的汇总数据 `/phoebus/equipmentPanel/queryEccYield`
    equipmentPanelQueryEccYield:(data)=> GET.get('/phoebus/equipmentPanel/queryEccYield',data),
    // 查询ecc层级数据 /phoebus/equipmentPanel/queryEccLevel
    equipmentPanelQueryEccLevel:(data)=> GET.get('/phoebus/equipmentPanel/queryEccLevel',data),
    // 查询ecc趋势数据 `/phoebus/equipmentPanel/queryEccDate`
    equipmentPanelQueryEccDate:(data)=> GET.get('/phoebus/equipmentPanel/queryEccDate',data),
    // 查询微逆状态 `/equipmentPanel/queryMicStatus`
    equipmentPanelQueryMicStatus:(data)=> GET.get('/phoebus/equipmentPanel/queryMicStatus',data),
    // 查询pv状态 `/equipmentPanel/queryMicStatus`
    equipmentPanelQueryPvStatus:(data)=> GET.get('/phoebus/equipmentPanel/queryPvStatus',data),
    // 查询pv数据 `/phoebus/equipmentPanel/queryInvData`
    equipmentPanelQueryInvData:(data)=> GET.get('/phoebus/equipmentPanel/queryInvData',data),
    // 查询ECC空间布局 `/phoebus/equipmentPanel/queryEccSpace`
    equipmentPanelQueryEccSpace:(data)=> GET.get('/phoebus/equipmentPanel/queryEccSpace',data),
    // 查询微逆表格数据 /phoebus/equipmentPanel/queryMicroYield
    equipmentPanelQueryMicroYield:(data)=> GET.get('/phoebus/equipmentPanel/queryMicroYield',data),
    // 查询微逆层级 `/phoebus/equipmentPanel/queryMicLevel`
    equipmentPanelQueryMicLevel:(data)=> GET.get('/phoebus/equipmentPanel/queryMicLevel',data),
    // 查询微逆的空间布局 /phoebus/equipmentPanel/queryMicSpace
    equipmentPanelQueryMicSpace:(data)=> GET.get('/phoebus/equipmentPanel/queryMicSpace',data),
    // 编辑卡片“知道了”状态提示 /userIndex/isKnown
    userIndexIsKnown:(data)=> POST.post_form('/phoebus/userIndex/isKnown',data),
    // RSD列表 /devRsd/getPage
    devRsdGetPage:(params)=> POST.post_form('/phoebus/devRsd/getPage',params),
    // 查询电站是否开启10S ts/site/tsEnable
    tsSiteTsEnable:(params)=> GET.get('/phoebus/ts/site/tsEnable',params),
    // 查询温度单位
    getUserTemp:(params)=> GET.get('/phoebus/user/getUserTemp',params),
    // 充电桩卡获取电站下所有充电桩的总发电量 /overview/cpCardTotal
    getCpCardTotal:(params)=> POST.post_form('/phoebus/overview/cpCardTotal',params),
    // 获取ECC设备列表 /coreKit/getEccPage
    getEccPage:(params)=> POST.post_json('/phoebus/coreKit/getEccPage',params),
    // 获取Ecc详情 /coreKit/eccInfo
    getEccInfo:(params)=> GET.get('/phoebus/coreKit/eccInfo',params),
    // 查询微逆的基本信息 /coreKit/microInfo
    getMicroInfo:(params)=> GET.get('/phoebus/coreKit/microInfo',params),
    // 查询组件告警 /coreKit/pvFault
    getPvFault:(params)=> GET.get('/phoebus/coreKit/pvFault',params),
    // 查询组件告警信息 /coreKit/pvFaultInfo
    getPvFaultInfo:(params)=> GET.get('/phoebus/coreKit/pvFaultInfo',params),
    // 分页获取电站下面白名单卡号 /rfid/getRfidPageList
    getRfidPageList:(params)=> POST.post_json('/phoebus/rfid/getRfidPageList',params),
    // 删除rfid卡 /rfid/delRfid
    delRfid:(params)=> POST.post_form('/phoebus/rfid/delRfid',params),
    // 获取电站充电电价 /site/getChargePrice
    getChargePrice:(params)=> GET.get('/phoebus/site/getChargePrice',params),
    // 检查rfid是否存在或者合法 /rfid/checkRfid
    checkRfid:(params)=> GET.get('/phoebus/rfid/checkRfid',params),
    // 新增或修改rfid卡片（带id就是修改，id没有就是新增 /rfid/addRfid
    addRfid:(params)=> POST.post_json('/phoebus/rfid/addRfid',params),
    // 电站充电电价修改 /site/setChargePrice
    setChargePrice:(params)=> POST.post_json('/phoebus/site/setChargePrice',params),
    // 查询电站下的设备 /site/inverterParams
    getSiteInverterParams: (params) => POST.post_form('/phoebus/site/inverterParams', params),
    // 查询数据间隔指令设置状态 /site/sttStatus
    querySiteSttStatus: (params) => POST.post_form('/phoebus/site/sttStatus', params),
    // 查询电站上报时间间隔的状态 /site/siteSttSup
    querySiteSttSup: (params) => POST.post_form('/phoebus/site/siteSttSup', params),
    // 电站是否开启实时数据 
    siteenable: (params) => POST.post_json_v6('/zeus/v1/ts/siteenable', params),
    // 设备是否支持实时数据 /ts/inverter/isSupport
    inverterIsSupport: (params) => GET.get('/phoebus/ts/inverter/isSupport', params),
    getUserCenterUserInfo: (params) => GET.get_v6('/unionUser/web/v1/user/get/userInfo', params),
}
